// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useDrawer, useModal, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Button from '@shared/design/components/Button';
import Table, {TableComponents} from '@shared/design/components/Table';
import TextTooltip from '@shared/design/components/TextTooltip';
import Toast from '@shared/design/components/Toast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CreateLeadProviderDrawer from 'modules/LeadProvider/components/CreateLeadProviderDrawer';
import UpdateLeadProviderDrawer from 'modules/LeadProvider/components/UpdateLeadProviderDrawer';
import UpdateLeadProviderStatusModal from 'modules/LeadProvider/components/UpdateLeadProviderStatusModal';
import {LeadProviderGlobalIntegration} from 'modules/LeadProvider/components/types';

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const TableText = Styled.Text`
  ${Typography.Body}
`;

const LeadProviderPage = () => {
  const createLeadProviderDrawer = useDrawer({name: 'Create Lead Provider Drawer'});
  const {loading, data, refetch} = useQuery(LeadProviderPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <AdminAppPage
      title={'Lead Providers'}
      HeaderActionElement={
        <React.Fragment>
          <Button
            text={'Create Lead Provider'}
            iconLeft={Icon.Plus}
            onPress={createLeadProviderDrawer.handleOpen}
          />
          <CreateLeadProviderDrawer
            isOpen={createLeadProviderDrawer.isOpen}
            handleClose={createLeadProviderDrawer.handleClose}
            refetch={refetch}
          />
        </React.Fragment>
      }
    >
      <ScrollView contentContainerStyle={{flex: 1, padding: 24}}>
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <LeadProvidersTable
              integrations={data.leadProviderGlobalIntegrations}
              refetch={refetch}
            />
          )}
        </Loading>
      </ScrollView>
    </AdminAppPage>
  );
};

const LeadProvidersTable = ({
  integrations,
  refetch,
}: {
  integrations: LeadProviderGlobalIntegration[];
  refetch: ReturnType<typeof useQuery>['refetch'];
}) => {
  const copiedToast = useToast({
    ToastComponent: Toast,
    message: `Copied to clipboard.`,
  });

  const columnDefinitions = [
    {
      headerLabel: 'Status',
      flex: 1,
      cellComponent: (integration: LeadProviderGlobalIntegration) => (
        <Badge
          isResponsive
          label={integration.status[0].toUpperCase() + integration.status.substring(1)}
          color={integration.status === 'active' ? colors.green.status : colors.orange.status}
          style={{marginVertical: 2}}
        />
      ),
    },
    {
      headerLabel: 'Name',
      flex: 2,
      cellText: (integration: LeadProviderGlobalIntegration) => integration.name,
    },
    {
      headerLabel: 'Description',
      flex: 3,
      cellText: (integration: LeadProviderGlobalIntegration) => integration.description,
    },
    {
      headerLabel: 'URL',
      flex: 5,
      cellComponent: (integration: LeadProviderGlobalIntegration) => (
        <LinkButton
          onPress={() => {
            navigator.clipboard.writeText(integration.url);
            copiedToast.handleToast();
          }}
        >
          <LinkText>{integration.url}</LinkText>
        </LinkButton>
      ),
    },
    {
      headerLabel: 'Created By',
      flex: 2,
      cellComponent: (integration: LeadProviderGlobalIntegration) => (
        <TextTooltip
          text={
            `Created: ${Datetime.convertToDisplayDate(integration.createdAt, Datetime.DISPLAY_DATE)}` +
            '\n' +
            `Updated: ${Datetime.convertToDisplayDate(integration.updatedAt, Datetime.DISPLAY_DATE)}`
          }
          placement={'top'}
          isEnabledMobileToast={false}
        >
          <TableComponents.PrimaryText>
            {integration.createdByUser.fullName}
          </TableComponents.PrimaryText>
        </TextTooltip>
      ),
    },
    {
      headerLabel: 'Companies',
      flex: 2,
      cellText: (integration: LeadProviderGlobalIntegration) =>
        `${integration.companies.length} Companies`,
    },
    {
      flex: 1,
      cellStyle: {alignItems: 'center'},
      actions: (integration: LeadProviderGlobalIntegration) => [
        {
          text: 'Edit',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          actionHook: {
            hook: useDrawer,
            hookArgument: {
              name: 'Update Lead Provider Drawer',
            },
            renderComponent: ({
              isOpen,
              handleClose,
              hookKey,
            }: {
              isOpen: boolean;
              handleClose: () => void;
              hookKey: string;
            }) => {
              return (
                <UpdateLeadProviderDrawer
                  key={hookKey}
                  integration={integration}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  refetch={refetch}
                />
              );
            },
          },
        },
        {
          text: 'Deactivate',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          isDisabled: integration.status === 'inactive',
          actionHook: {
            hook: useModal,
            hookArgument: {
              name: 'Deactive Lead Provider Modal',
            },
            renderComponent: ({
              isOpen,
              handleClose,
              hookKey,
            }: {
              isOpen: boolean;
              handleClose: () => void;
              hookKey: string;
            }) => {
              return (
                <UpdateLeadProviderStatusModal
                  key={hookKey}
                  integration={integration}
                  status={'inactive'}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  refetch={refetch}
                />
              );
            },
          },
        },
        {
          text: 'Activate',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          isDisabled: integration.status === 'active',
          actionHook: {
            hook: useModal,
            hookArgument: {
              name: 'Active Lead Provider Modal',
            },
            renderComponent: ({
              isOpen,
              handleClose,
              hookKey,
            }: {
              isOpen: boolean;
              handleClose: () => void;
              hookKey: string;
            }) => {
              return (
                <UpdateLeadProviderStatusModal
                  key={hookKey}
                  integration={integration}
                  status={'active'}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  refetch={refetch}
                />
              );
            },
          },
        },
      ],
    },
  ];

  return (
    <Table items={integrations} itemKey={'name'} columnDefinitions={columnDefinitions} hasBorder />
  );
};

LeadProviderPage.query = gql`
    query LeadProviderPage {
        ${gql.query}
        viewer {
            id
        }
        leadProviderGlobalIntegrations {
            id
            createdAt
            updatedAt
            name
            description
            status
            url
            createdByUser {
                id 
                fullName
            }
            companies {
                id
                name
            }
        }
    }
`;

export default LeadProviderPage;
