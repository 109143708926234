// Libraries
import React from 'react';

// Supermove
import {useQuery} from '@supermove/hooks';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import {LeadProviderGlobalIntegration} from 'modules/LeadProvider/components/types';
import useUpdateLeadProviderGlobalIntegrationMutation from 'modules/LeadProvider/components/useUpdateLeadProviderGlobalIntegrationMutation';

const UpdateLeadProvideStatusModal = ({
  integration,
  status,
  isOpen,
  handleClose,
  refetch,
}: {
  integration: LeadProviderGlobalIntegration;
  status: 'active' | 'inactive';
  isOpen: boolean;
  handleClose: () => void;
  refetch: ReturnType<typeof useQuery>['refetch'];
}) => {
  const {form, submitting, handleSubmit} = useUpdateLeadProviderGlobalIntegrationMutation({
    updateLeadProviderGlobalIntegrationForm: {
      leadProviderGlobalIntegrationId: integration.id,
      name: integration.name,
      description: integration.description,
      status,
      url: integration.url,
    },
    onSuccess: () => {
      form.resetForm();
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => console.error({errors}),
  });

  return (
    <CautionModal
      title={status === 'active' ? 'Activate Lead Provider' : 'Deactivate Lead Provider'}
      message={
        status === 'active'
          ? 'This will allow users in office app to select this lead provider.'
          : 'This will prevent users in office app from selecting this lead provider. This will not remove any active integrations currently using this lead provider.'
      }
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

export default UpdateLeadProvideStatusModal;
